import { HTTP_LOCKDOWN } from '@/api/http-common'
import { HTTP_LEADERBOARD } from '@/api/http-common'
import { BackendVersion } from '@/types/responses'
import { TokenPayload } from '@/types/jwt'
import { Question, Code, Player, Clue, PlayerScore, PlayerRank, RankingPlayer, GameSupportData } from '@/types/game'


export const backendAPI = {

 
  getVersion: () => {
    console.log("BackendAPI: getVersion")
    return new Promise<string>((resolve, reject) => {
      HTTP_LOCKDOWN.get<BackendVersion>("/app/version")
      .then(response => {
        //console.log("arrivedvvv: ", response)
        if(response.status === 200) {
          resolve(response.data.message)
        }else{
          reject("boh")
        }
      })
      .catch(e => {
        // TODO: gestire l'errore
        // e.message? e.code?
        reject(e)
      })
    })
  },


  getGameSupportData: () => {
    console.log("BackendAPI: getGameSupportData")
    return new Promise<GameSupportData>((resolve, reject) => {
      HTTP_LOCKDOWN.get<GameSupportData>("/game_data")
      .then(response => {
        console.log("arrived: ", response)
        if(response.status === 200) {
          resolve(response.data)
        }else{
          reject("boh")
        }
      })
      .catch(e => {
        // TODO: gestire l'errore
        // e.message? e.code?
        reject(e)
      })
    })
  },


  authentication: (username: string, password: string) => {
    console.log("BackendAPI: authentication")



    return new Promise<TokenPayload>((resolve, reject) => {
      HTTP_LEADERBOARD.post<TokenPayload>("/authplayer", {nickname: username, password: password})
      .then(response => {
        console.log("arrivedf: ", response)
        if(response.status === 200) {
          resolve(response.data)
        }
      })
      .catch(error => {
        // TODO: gestire l'errore
        // e.message? e.code?
        reject(error)
      })
    })
  },


  isPlayerAuthenticated: (playerID: number) => {
    console.log("BackendAPI: isPlayerAuthenticated")
    return new Promise<boolean>((resolve, reject) => {
      backendAPI.getPlayer(playerID)
      .then(success => {
        console.log("bene")
        resolve(true)
      })
      .catch(error => {
        console.log("male")
        resolve(false)
      })
    })
  },


  getPlayer: (playerID: number) => {
    console.log("BackendAPI: getPlayer")
    return new Promise<Player>((resolve, reject) => {
      HTTP_LOCKDOWN.get<Player>(`/player`)
      .then(response => {
        console.log("bene bene")
        if(response.status === 200) {
          resolve(response.data)
        }else{
          reject("player not found")
        }
      })
      .catch(error => {
        console.log("malemale")
        reject(error)
      })
    })
  },


  registration: (playerID: number) => {
    console.log("BackendAPI: registration")
    return new Promise<string>((resolve, reject) => {
      HTTP_LOCKDOWN.post<Player>("/players", {id: playerID})
      .then(response => {
        resolve("Giocatore registrato correttamente")
      })
      .catch(error => {
        reject(error)
      })
    })
  },


  tryToUnlockCode: (playerID: number, text: string) => {
    console.log("BackendAPI: tryToUnlockCode")
    return new Promise<Code>((resolve, reject) => {
      HTTP_LOCKDOWN.post(`/player/codes`, {text: text})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        // error = error.response
        console.log(error)
        if(error.response?.data?.detail !== undefined) {
          reject(error.response.data.detail)
        }else{
          reject("Codice non valido! :(")
        }
      })
    })
  },


  getUnresolvedQuestions: (playerID: number) => {
    console.log("BackendAPI: getUnresolvedQuestions")


    return new Promise<Question[]>((resolve, reject) => {
      HTTP_LOCKDOWN.get(`/player/questions/unsolved`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }
      })
      .catch(error => {
        if(error.response.status === 404) {
          console.log("nessuna domanda unresolved trovata")
          resolve([])
        }else{
          reject(error.response.data.detail)
        }
      })
    })
  },


  getSolvedQuestions: (playerID: number) => {
    console.log("BackendAPI: getSolvedQuestions")
    return new Promise<Question[]>((resolve, reject) => {
      HTTP_LOCKDOWN.get(`/player/questions/solved`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }
      })
      .catch(error => {
        if(error.response.status === 404) {
          console.log("nessuna domanda solved trovata")
          resolve([])
        }else{
          reject(error.response.data.detail)
        }
      })
    })
  },


  getUnlockedCodes: (playerID: number) => {
    console.log("BackendAPI: getUnlockedCodes")
    return new Promise<Code[]>((resolve, reject) => {
      HTTP_LOCKDOWN.get(`/player/codes`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }
      })
      .catch(error => {
        reject(error)
      })
    })
  },


  getUnlockedClues: (playerID: number) => {
    console.log("BackendAPI: getUnlockedClues")
    return new Promise<Clue[]>((resolve, reject) => {
      HTTP_LOCKDOWN.get(`/player/clues`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }
      })
      .catch(error => {
        reject(error)
      })
    })
  },


  getUnlockedUnsolvedClues: (playerID: number) => {
    console.log("BackendAPI: getUnlockedUnsolvedClues")
    return new Promise<Clue[]>((resolve, reject) => {
      HTTP_LOCKDOWN.get(`/player/clues/unsolved`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }
      })
      .catch(error => {
        reject(error)
      })
    })
  },

  getUnlockedSolvedClues: (playerID: number) => {
    console.log("BackendAPI: getUnlockedSolvedClues")

    return new Promise<Clue[]>((resolve, reject) => {

      HTTP_LOCKDOWN.get(`/player/clues/solved`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }
      })
      .catch(error => {
        reject(error)
      })
    })
  },



  playerTryToResolveQuestion: (playerID: number, questionID: number, answerID: number) => {
    console.log("BackendAPI: playerTryToResolveQuestion")
    return new Promise<string>((resolve, reject) => {

      const data = {
        id: answerID
      }
      HTTP_LOCKDOWN.put(`/player/unresolved/${questionID}`, data)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }else{
          reject(response.data)
        }
      })
      .catch(error => {
        reject(error)
      })
    })
  },


  getPlayerScore: (playerID: number) => {
    console.log("BackendAPI: getPlayerScore")
    return new Promise<PlayerScore>((resolve, reject) => {
      HTTP_LOCKDOWN.get(`/player/score`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }else{
          reject(response.data)
        }
      })
      .catch(error => {
        reject(error)
      })
    })
  },

  
  getPlayerRank: (playerID: number) => {
    console.log("BackendAPI: getPlayerRank")
    return new Promise<PlayerRank>((resolve, reject) => {
      HTTP_LOCKDOWN.get(`/player/rank`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }else{
          reject(response.data)
        }
      })
      .catch(error => {
        reject(error)
      })
    })
  },


  getRanking: () => {
    console.log("BackendAPI: getRanking")
    return new Promise<Array<RankingPlayer>>((resolve, reject) => {
      HTTP_LOCKDOWN.get(`/admin/ranking`)
      .then(response => {
        console.log("response:", response)
        if(response.status === 200) {
          resolve(response.data)
        }else{
          reject(response.data)
        }
      })
      .catch(error => {
        reject(error)
      })
    })
  },


}
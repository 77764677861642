import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router"
import * as state from "@/store/modules/app"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/codes",
    name: "Codes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Codes.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/clues",
    name: "Clues",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Clues.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/questions",
    name: "Questions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Questions.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/logout",
    name: "Logout",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Logout.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/dash",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/help",
    name: "Help",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Help.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/ranking",
    name: "Ranking",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Ranking.vue"),
    meta: {
      guest: true
    }
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("beforeEach: ", to.fullPath, from.fullPath)
  // to.matched.some(record => {
  //   console.log(record.meta)
  // })
  if(to.matched.some(record => record.meta.requiresAuth)) {
      // la pagina che si vuole visitare richiede l'autenticazione
      console.log("beforeEach: la pagina richiede autenticazione")
      // if (localStorage.getItem('jwt') == null) {
      if (state.getters.isLogged === false) {
          // non c'è salvato nessun token. Mando a login
          next({
              path: '/login',
              query: { redirect: to.fullPath }
          })
      } else {
          // il token c'è.
          const item = localStorage.getItem('jwt')
          if(item) {
            next()
            // const user = JSON.parse(item)
            // if(to.matched.some(record => record.meta.is_admin)) {
                
            //     if(user.is_admin == 1){
            //         next()
            //     }
            //     else{
            //         next({ name: 'userboard'})
            //     }
            // }else {
            //     next()
            // }
          }

      }
  } else if(to.matched.some(record => record.meta.guest)) {
    console.log("beforeEach: la pagina è per guest")
    next()
    // if(localStorage.getItem('jwt') == null){
    //     next()
    // }else{
    //   next({
    //     path: '/login',
    //     query: { redirect: to.fullPath }
    //   })
    // }
  }else {
    next()
  }
})


export default router;

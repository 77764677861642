import { BareActionContext } from "vuex-typex"
import { storeBuilder, RootState } from "@/store/root-state"
import { Question, Clue, RankingPlayer } from "@/types/game"
import { JWT, TokenPayload } from '@/types/jwt'
import jwt_decode from "jwt-decode"
import { backendAPI } from "@/api/backendAPI"


// state


export class AppState {
    backendVersion = ""
    logged = false
    unlockedClues: Clue[] = []
    unsolvedClues: Clue[] = []
    solvedClues: Clue[] = []
    unresolvedQuestions: Question[] = []
    solvedQuestions: Question[] = []
    ranking: RankingPlayer[] = []
    score = 0
    rank = 0
}


const builder = storeBuilder.module<AppState>("app", new AppState())


// getters

// const isLogged = builder.read(() => {
//         const token = localStorage.getItem('jwt')
//         if (token) {
//             return true
//         } else {
//             return false
//         }
//     },
//     "isLogged"
// )


const userID = builder.read(() => {
        const token = localStorage.getItem('jwt')
        if(token) {
            const decoded: JWT = jwt_decode(token);
            console.log("decoded:", decoded)
            return decoded.player_id
        }
        return null
    },
    "userID"
)

const backendVersion = builder.read((state: AppState) => state.backendVersion, "backendVersion")
const isLogged = builder.read((state: AppState) => state.logged, "isLogged")
const unresolvedQuestions = builder.read((state: AppState) => state.unresolvedQuestions, "unresolvedQuestions")
const solvedQuestions = builder.read((state: AppState) => state.solvedQuestions, "solvedQuestions")

const unlockedClues = builder.read((state: AppState) => state.unlockedClues, "unlockedClues")
const unsolvedClues = builder.read((state: AppState) => state.unsolvedClues, "unsolvedClues")
const solvedClues = builder.read((state: AppState) => state.solvedClues, "solvedClues")

const unlockedCluesNumber = builder.read((state: AppState) => state.unlockedClues.length, "unlockedCluesNumber")
const unsolvedCluesNumber = builder.read((state: AppState) => state.unsolvedClues.length, "unsolvedCluesNumber")
const solvedCluesNumber = builder.read((state: AppState) => state.solvedClues.length, "solvedCluesNumber")

const score = builder.read((state: AppState) => state.score, "score")
const rank = builder.read((state: AppState) => state.rank, "rank")

const ranking = builder.read((state: AppState) => state.ranking, "ranking")


export const getters = {

    get backendVersion(): string {
        return backendVersion()
    },

    get isLogged(): boolean {
        return isLogged()
    },

    get userID(): number | null {
        return userID()
    },

    get unresolvedQuestions(): Question[] {
        return unresolvedQuestions()
    },

    get solvedQuestions(): Question[] {
        return solvedQuestions()
    },

    get unlockedClues(): Clue[] {
        return unlockedClues()
    },

    get unsolvedClues(): Clue[] {
        return unsolvedClues()
    },

    get solvedClues(): Clue[] {
        return solvedClues()
    },

    get unlockedCluesNumber(): number {
        return unlockedCluesNumber()
    },

    get unsolvedCluesNumber(): number {
        return unsolvedCluesNumber()
    },

    get solvedCluesNumber(): number {
        return solvedCluesNumber()
    },

    get score(): number {
        return score()
    },

    get rank(): number {
        return rank()
    },

    get ranking(): RankingPlayer[] {
        return ranking()
    },

}


// mutations

const setBackendVersion = builder.commit((state: AppState, data: string) => state.backendVersion = data, "setBackendVersion")
const setUnlockedClues = builder.commit((state: AppState, clues: Clue[]) => state.unlockedClues = clues, "setUnlockedClues")
const setUnsolvedClues = builder.commit((state: AppState, clues: Clue[]) => state.unsolvedClues = clues, "setUnsolvedClues")
const setSolvedClues = builder.commit((state: AppState, clues: Clue[]) => state.solvedClues = clues, "setSolvedClues")

const setUnresolvedQuestions = builder.commit((state: AppState, questions: Question[]) => state.unresolvedQuestions = questions, "setUnresolvedQuestions")
const setSolvedQuestions = builder.commit((state: AppState, questions: Question[]) => state.solvedQuestions = questions, "setSolvedQuestions")
const setLogged = builder.commit((state: AppState, logged: boolean) => state.logged = logged, "setLogged")
const setPlayerScore = builder.commit((state: AppState, score: number) => state.score = score, "setPlayerScore")
const setPlayerRank = builder.commit((state: AppState, rank: number) => state.rank = rank, "setPlayerRank")

const setRanking = builder.commit((state: AppState, ranking: RankingPlayer[]) => state.ranking = ranking, "setRanking")


export const mutations = {
    setBackendVersion: setBackendVersion,
    setUnlockedClues: setUnlockedClues,
    setUnsolvedClues: setUnsolvedClues,
    setSolvedClues: setSolvedClues,
    setUnresolvedQuestions: setUnresolvedQuestions,
    setSolvedQuestions: setSolvedQuestions,
    setLogged: setLogged,
    setPlayerScore: setPlayerScore,
    setPlayerRank: setPlayerRank,
    setRanking: setRanking,
}


// actions

const fetchBackendVersion = builder.dispatch((context: BareActionContext<AppState, RootState>) => {

    console.log("fetching backend version..")
    return new Promise((resolve, reject) => {
        backendAPI.getVersion()
        .then(data => {
            mutations.setBackendVersion(data)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching della versione del backend")
        })
    })
}, "fetchBackendVersion")


const checkLogged = builder.dispatch((context: BareActionContext<AppState, RootState>) => {
    const storedToken = localStorage.getItem('jwt')
    if (storedToken == null) {
        mutations.setLogged(false)
    }else{
        mutations.setLogged(true)
    }
}, "checkLogged")


// const login = builder.dispatch((context: BareActionContext<AppState, RootState>, token: string) => {
//     localStorage.removeItem("jwt")
//     localStorage.setItem("jwt", token)
//     const storedToken = localStorage.getItem('jwt')
//     if (storedToken == token) {
//         mutations.setLogged(true)
//     }else{
//         mutations.setLogged(false)
//     }
// }, "login")
const login = builder.dispatch((context: BareActionContext<AppState, RootState>, tokens: TokenPayload) => {

    // refresh token
    localStorage.removeItem("refresh_jwt")
    localStorage.setItem("refresh_jwt", tokens.refresh_token)

    // access token
    localStorage.removeItem("jwt")
    localStorage.setItem("jwt", tokens.access_token)
    const storedToken = localStorage.getItem('jwt')
    if (storedToken == tokens.access_token) {
        mutations.setLogged(true)
    }else{
        mutations.setLogged(false)
    }
}, "login")


const logout = builder.dispatch((context: BareActionContext<AppState, RootState>) => {
    localStorage.removeItem("jwt")
    localStorage.removeItem("refresh_jwt")
    if (localStorage.getItem('jwt') == null) {
        console.log("imposto logged a falso nello stato")
        mutations.setLogged(false)
    }else{
        console.log(localStorage.getItem('jwt'))
    }
}, "logout")


const fetchUnlockedClues = builder.dispatch((context: BareActionContext<AppState, RootState>, playerID: number) => {
    console.log("fetching unlocked Clues..")
    return new Promise((resolve, reject) => {
        backendAPI.getUnlockedClues(playerID)
        .then(data => {
            mutations.setUnlockedClues(data)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching degli indizi sbloccati")
        })
    })
}, "fetchUnlockedClues")


const fetchUnsolvedClues = builder.dispatch((context: BareActionContext<AppState, RootState>, playerID: number) => {
    console.log("fetching unsolved Clues..")
    return new Promise((resolve, reject) => {
        backendAPI.getUnlockedUnsolvedClues(playerID)
        .then(data => {
            mutations.setUnsolvedClues(data)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching degli indizi non risolti")
        })
    })
}, "fetchUnsolvedClues")


const fetchSolvedClues = builder.dispatch((context: BareActionContext<AppState, RootState>, playerID: number) => {
    console.log("fetching solved Clues..")
    return new Promise((resolve, reject) => {
        backendAPI.getUnlockedSolvedClues(playerID)
        .then(data => {
            mutations.setSolvedClues(data)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching degli indizi risolti")
        })
    })
}, "fetchSolvedClues")



const fetchUnresolvedQuestions = builder.dispatch((context: BareActionContext<AppState, RootState>, playerID: number) => {
    console.log("fetching unresolved Questions..")
    return new Promise((resolve, reject) => {
        backendAPI.getUnresolvedQuestions(playerID)
        .then(data => {
            mutations.setUnresolvedQuestions(data)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching delle domande unresolved")
        })
    })
}, "fetchUnresolvedQuestions")


const fetchSolvedQuestions = builder.dispatch((context: BareActionContext<AppState, RootState>, playerID: number) => {
    console.log("fetching unresolved Questions..")
    return new Promise((resolve, reject) => {
        backendAPI.getSolvedQuestions(playerID)
        .then(data => {
            mutations.setSolvedQuestions(data)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching delle domande solved")
        })
    })
}, "fetchSolvedQuestions")


const fetchPlayerScore = builder.dispatch((context: BareActionContext<AppState, RootState>, playerID: number) => {
    console.log("fetching player score..")
    return new Promise((resolve, reject) => {
        backendAPI.getPlayerScore(playerID)
        .then(data => {
            mutations.setPlayerScore(data.score)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching del player score")
        })
    })
}, "fetchPlayerScore")


const fetchPlayerRank = builder.dispatch((context: BareActionContext<AppState, RootState>, playerID: number) => {
    console.log("fetching player rank..")
    return new Promise((resolve, reject) => {
        backendAPI.getPlayerRank(playerID)
        .then(data => {
            mutations.setPlayerRank(data.rank)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching del player rank")
        })
    })
}, "fetchPlayerRank")


const fetchRanking = builder.dispatch((context: BareActionContext<AppState, RootState>) => {
    console.log("fetching ranking..")
    return new Promise((resolve, reject) => {
        backendAPI.getRanking()
        .then(data => {
            mutations.setRanking(data)
            resolve()
        })
        .catch(error => {
            console.log(error)
            reject("Errore durante il fetching della classifica")
        })
    })
}, "fetchRanking")



export const actions = {
    fetchBackendVersion: fetchBackendVersion,
    checkLogged: checkLogged,
    login: login,
    logout: logout,
    fetchUnresolvedQuestions: fetchUnresolvedQuestions,
    fetchSolvedQuestions: fetchSolvedQuestions,

    fetchUnlockedClues: fetchUnlockedClues,
    fetchUnsolvedClues: fetchUnsolvedClues,
    fetchSolvedClues: fetchSolvedClues,

    fetchPlayerScore: fetchPlayerScore,
    fetchPlayerRank: fetchPlayerRank,

    fetchRanking: fetchRanking,
}

import axios from "axios";
import { TokenPayload } from '@/types/jwt'
import * as state from "@/store/modules/app"



const HTTP_LEADERBOARD = axios.create({

  // baseURL: `http://${window.location.hostname}:5000/api`,
  // baseURL: "http://10.0.0.13:5000/api",
  baseURL: "https://apileba.grandecaccia.it/api",
  timeout: 10000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
})


const HTTP_LOCKDOWN = axios.create({
  // baseURL: `http://${window.location.hostname}:8000`,
  // baseURL: "http://10.0.0.13:8000",
  baseURL: "https://apiloba.grandecaccia.it",
  timeout: 10000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
})


HTTP_LOCKDOWN.interceptors.request.use(
  // intercetto QUALSIASI richiesta verso il Lockdown Backend
  // e ci appiccico il token nelle headers.
  // Se c'è qualche endpoint che non richiede il token,
  // se lo becca lo stesso che tanto non fa storie
  (requestConfig) => {
    const token = localStorage.getItem("jwt")

    requestConfig.headers[
      "Authorization"
    ] = `bearer ${token}`;
    // restituisco la promise risolta con le config della richiesta aggiornate
    return Promise.resolve(requestConfig)
  },
  (error) => {
    // restituisco pari pari l'errore che trovo
    return Promise.reject(error)
  }
)


HTTP_LOCKDOWN.interceptors.response.use(
  // intercetto tutte le risposte a QUALSIASI mia richiesta al Lockdown backend
  (response) => {
    // in caso di risposta positiva
    // la restituisco pari pari
    return response;
  },
  async (error) => {
    // in caso di risposta negativa
    // controllo se c'è un problema di autenticazione
    console.log("ma che oooooh: ", error.response)
    if (error.response.status === 403) {
      console.log("errore di autenticazione. Provo a refreshare il token --------------------------------------------------------------")
      // errore di autenticazione.
      // Provo a refreshare il token

      // prendo vecchio token e refresh token
      const token = localStorage.getItem('jwt')
      const refreshToken = localStorage.getItem('refresh_jwt')
      // impacchetto un payload
      const payload = {
        access_token: token,
        refresh_token: refreshToken,
      };

      let response = null
      try {
        // mando richiesta ma al Leaderboard Backend
        // per un nuovo token
        response = await HTTP_LEADERBOARD.post<TokenPayload>("/refreshtoken", payload)
      } catch (err) {
        // neppure questa chiamata è andata a buon fine.
        // forse anche il refresh_token è scaduto
        // non posso farci niente. Sloggo
        await state.actions.logout()
        // e restiuisco l'errore
        return Promise.reject(err)
      }


      if (response.status === 200) {
        // nuovi tokens!
        const newToken = response.data.access_token
        const newRefreshToken = response.data.refresh_token

        console.log("-----------------")

        console.log(`token ${token}`)
        console.log(`refreshToken ${refreshToken}`)
        console.log(`newToken ${newToken}`)
        console.log(`newRefreshToken ${newRefreshToken}`)

        console.log("-----------------")

        if (newToken && newRefreshToken) {
          // mi riloggo coi nuovi token
          state.actions.login({ access_token: newToken, refresh_token: newRefreshToken })

          // aggiorno gli headers della vecchia request col nuovo token
          error.config.headers[
            "Authorization"
          ] = `bearer ${newToken}`;

          // aggiorno la nuova request
          const newConfig = {
            "url": error.config.url,
            "method": error.config.method,
            "headers": error.config.headers,
            "baseURL": error.config.baseURL,
            "timeout": error.config.timeout,
          }

          // rimando la request originaria
          return axios(newConfig);
        } else {
          // il Leaderboard backend mi ha restituito tokens invalidi
          // ma che diavolo...
          return Promise.reject(`Invalid tokens: ${newToken}, ${newRefreshToken}`)
        }

      }

    } else {
      // non è un problema di autenticazione.
      // la request è stata rifiutata per un altro motivo.
      // restituisco l'errore
      return Promise.reject(error);
    }

  }
);



export { HTTP_LOCKDOWN, HTTP_LEADERBOARD }
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as state from "@/store/modules/app"
import vuetify from "./plugins/vuetify";
import VueCompositionAPI from "@vue/composition-api"


// if (process.env.NODE_ENV === 'production') {
Vue.config.productionTip = false; // Disabilita il messaggio di avviso in console
Vue.config.devtools = false;      // Disabilita gli strumenti di sviluppo Vue
// }


console.log("config:")
console.log(`LOCKDOWN BACKEND: https://apiloba.grandecaccia.it`)
console.log(`LEADERBOARD BACKEND: https://apileba.grandecaccia.it/api`)


Vue.use(VueCompositionAPI)

state.actions.checkLogged()

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");







































































import { backendAPI } from "@/api/backendAPI"
import * as state from "@/store/modules/app"
import * as auth from "@/utils/auth"
import { defineComponent, onMounted, ref, ComputedRef, computed, watch } from '@vue/composition-api'
import { getConnectionIcon } from "@/features/useIcons"


export default defineComponent({

  name: 'App',

  components: {},

  setup() {

    const { connectionIcon } = getConnectionIcon()

    const version: ComputedRef = computed(() => state.getters.backendVersion)
    const logged: ComputedRef = computed(() => state.getters.isLogged)
    const drawer = ref(false)
    const group = ref(null)

    // anteprima indizi e domande non risolti
    const unsolvedClues: ComputedRef = computed(() => state.getters.unsolvedClues.length)
    const unresolvedQuestions: ComputedRef = computed(() => state.getters.unresolvedQuestions.length)
    const playerID: ComputedRef = computed(() => state.getters.userID)

    watch(group, (currentValue, oldValue) => {
      // si è cliccato su una voce. Chiudo il menu
      drawer.value = false
    })

    onMounted(() => {
        state.actions.fetchBackendVersion()
        .then(() => {
            console.log("backend version fetchata")

            // fetcho domande e  indizi sbloccati
            // così aggiorno il numerino di fianco alla voce della pagina nel menu
            // di navigazione. Lo faccio anche qui nel caso si aggiorni la pagina
            state.actions.fetchUnsolvedClues(playerID.value)
            state.actions.fetchUnresolvedQuestions(playerID.value)
        })

    })

    return {
      connectionIcon,
      group,
      drawer,
      version,
      logged,
      unsolvedClues,
      unresolvedQuestions,
    }

  }

})

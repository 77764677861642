import * as state from "@/store/modules/app"
import { computed } from '@vue/composition-api'


function getConnectionIcon() {
    const connectionIcon = computed(() => {
        return (state.getters.isLogged) ? 'mdi-link-variant' : 'mdi-link-variant-off'
    })
    return {
        connectionIcon,
    }
}


export { getConnectionIcon }